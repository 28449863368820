import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import SlickPrev from "../images/svgs/slick-prev.svg"
import SlickNext from "../images/svgs/slick-next.svg"
import GalleryBlob from "../images/svgs/gallery-blob.svg"

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <SlickNext className={className} style={{ ...style }} onClick={onClick} />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <SlickPrev className={className} style={{ ...style }} onClick={onClick} />
  )
}

export default function Gallery() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    centerPadding: "0px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <StaticQuery
      query={graphql`
        query gallery {
          wpPage(id: { eq: "cG9zdDoyNQ==" }) {
            id
            homePage {
              gallery {
                id
                altText
                mediaItemUrl
              }
            }
          }
        }
      `}
      render={data => (
        <div className="slick-wrap">
          <Slider {...settings}>
            {data.wpPage.homePage.gallery.map(item => {
              return (
                <div className="slider-cont" key={item.id}>
                  <img
                    src={item.mediaItemUrl}
                    alt={item.altText || "Donut Worry"}
                  />
                </div>
              )
            })}
          </Slider>
          <GalleryBlob />
        </div>
      )}
    />
  )
}
